// initial state
const state = {
    dbInstance: null
}

// getters
const getters = {
    getDbInstance: state => state.dbInstance
}

// actions
const actions = {
    setDbInstance ({ commit }, db) {
        commit('setDbInstance', db);
    }
}

// mutations
const mutations = {
    setDbInstance (state, db) {
        state.dbInstance = db;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}