import axios  from "axios";
import { API_URL, API_SYNC } from "../../services/apienv";
import { Preferences } from '@capacitor/preferences';

// initial state
const state = {
    access_token: '',
    status: '',
    hasLoadedOnce: false,
    userData: {},
    errors: '',
}

// getters
const getters = {
    isAuthenticated: state => !!state.access_token,
    hasLoadedOnce: state => state.hasLoadedOnce,
    authStatus: state => state.status,
    authErrors: state => state.errors,
}

// actions
const actions = {
    async authLogin ({ commit }, payload) {
        let data = {
            username: payload.username,
            password: payload.password,
            assoc_code: payload.assoc_code,
        };

        commit("auth_login");
        commit('user_data', {});

        try {
            await Preferences.remove({ key: 'access_token' });

            axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

            const {value} = await Preferences.get({ key: 'assoc_code' });

            let resp = await axios.post(API_URL + '/' + value + '?redirect=api/scoringapp/login', data);
            let access_token = "Bearer " + JSON.parse(resp.data.success).access_token;

            await Preferences.set({
                key: 'access_token',
                value: access_token,
            });

            axios.defaults.headers.common["Authorization"] = access_token;

            commit("auth_success", access_token);
            commit('auth_error', '');

            return true;
        } catch (err) {
            // console.log('err', err);
            let errors = '';

            try {
                errors = err.response.data.errors ? err.response.data.errors.error : err.response.data.error;

                if ((typeof errors == 'string') && (JSON.parse(errors).error == "invalid_credentials" || JSON.parse(errors).error == "invalid_grant")) {
                    errors = {error: {invalid_credentials:'Invalid Credentials'}};
                }
            } catch (e) {
                errors = {error: {invalid_credentials: "Technical issue, please try again later."}};
            }

            commit('auth_error', errors);

            return false;
        }
    },
    async getToken ({ commit }) {
        const { value } = await Preferences.get({ key: 'access_token' });

        commit("get_token", value);
    },
    async authLogout ({commit}) {
        try {
            await Preferences.remove({ key: 'access_token' });

            const {value} = await Preferences.get({ key: 'assoc_code' });

            await axios.post(API_URL + '/' + value + '?redirect=api/scoringapp/logout');
            axios.defaults.headers.common["Authorization"] = '';

            commit("auth_logout");
        } catch (err) {
            // console.log(err);
        }
    }
}

// mutations
const mutations = {
    get_token(state, access_token) {
        state.access_token = access_token;
    },
    auth_login(state) {
        state.status = "loading";
    },
    auth_success (state, access_token) {
        state.status = "success";
        state.access_token = access_token;
        state.hasLoadedOnce = true;
    },
    auth_logout (state) {
        state.status = "logout";
    },
    auth_error (state, errors) {
        state.errors = errors;
    },
    user_data (state, user) {
        state.userData = user;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}