import { createStore, createLogger } from 'vuex'
import db from './modules/database'
import auth from './modules/auth'

const debug = process.env.NODE_ENV !== 'production'

export const store = createStore({
    modules: {
        db,
        auth,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})