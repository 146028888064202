import { createRouter, createWebHistory } from 'vue-router';
// import { createRouter, createWebHistory } from '@ionic/vue-router';
import { store } from '../store/index';

window.popStateDetected = false;
window.addEventListener('popstate', () => {
    window.popStateDetected = true
});

const ifAuthenticated = (to, from, next) => {
    store.dispatch('auth/getToken').then(() => {
        if (store.getters['auth/isAuthenticated']) {
            const IsBrowserBackButton = window.popStateDetected;
            window.popStateDetected = false;
            if (IsBrowserBackButton) {
                next(false);
                return;
            }

            next();
            return;
        } else {
            return next({
                name: 'Login',
                query: { redirect: to.fullPath },
            });
        }
    });
};

const ifNotAuthenticated = (to, from, next) => {
    store.dispatch('auth/getToken').then(() => {
        if (store.getters["auth/isAuthenticated"]) {
            next({ name: "Home" });
            return;
        } else {
            return next();
        }
    });
};

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/LoginPage.vue'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/HomePage.vue'),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/game/:gameid/period/:period',
        name: 'Game',
        props: (route) => ({ gameid: Number(route.params.gameid), period: Number(route.params.period) }),
        component: () => import('@/views/MatchPage.vue'),
        beforeEnter: ifAuthenticated,
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
