<template>
    <ion-app>
        <router-view/>
        <!-- <ion-router-outlet /> -->
    </ion-app>
</template>

<script>
import sqliteService from "@/services/sqlite-service";
import { useStore } from 'vuex';
import { IonApp, IonRouterOutlet} from '@ionic/vue';
import { defineComponent, onMounted} from "vue";

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonRouterOutlet
    },
    setup() {
        const { databaseStartup, initDbTables } = sqliteService();

        const store = useStore();

        onMounted(async () => {
            try {
                const db = await databaseStartup();

                await initDbTables();

                store.dispatch('db/setDbInstance', db);
            } catch (e) {
                console.log('database issues', e.message);
            }
        });

        return {

        };
  }
});
</script>