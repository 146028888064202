export const CREATE_TABLE = `
CREATE TABLE IF NOT EXISTS lmp_ng_venues  (
    "venue_id" INTEGER PRIMARY KEY AUTOINCREMENT,
    "venue_name" TEXT NOT NULL,
    "venue_slug" TEXT NOT NULL,
    "created_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "updated_at" TEXT DEFAULT CURRENT_TIMESTAMP
);

CREATE TABLE IF NOT EXISTS lmp_ng_courts(
    "court_id" INTEGER PRIMARY KEY AUTOINCREMENT,
    "court_name" TEXT NOT NULL,
    "c_venue_id" INTEGER NOT NULL,
    "created_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "updated_at" TEXT DEFAULT CURRENT_TIMESTAMP
);

CREATE TABLE IF NOT EXISTS lmp_ng_seasons  (
    "season_id" INTEGER PRIMARY KEY AUTOINCREMENT,
    "season_name" TEXT NOT NULL,
    "season_slug" TEXT NOT NULL,
    "s_sport_type_id" INTEGER NOT NULL,
    "created_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "updated_at" TEXT DEFAULT CURRENT_TIMESTAMP
);

CREATE TABLE IF NOT EXISTS lmp_ng_divisions  (
    "division_id" INTEGER PRIMARY KEY AUTOINCREMENT,
    "division_name" TEXT NOT NULL,
    "division_slug" TEXT NOT NULL,
    "d_season_id" INTEGER NOT NULL,
    "created_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "updated_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "stop_clock" NUMERIC DEFAULT 0,
    "periods" INTEGER,
    "period_time" INTEGER,
    "period_timeout" INTEGER,
    "max_personal_fouls" INTEGER,
    "fouls_before_bonus" INTEGER,
    "fouls_before_disq" INTEGER,
    "techfouls_before_disq" INTEGER,
    "unspfouls_before_disq" INTEGER
);

CREATE TABLE IF NOT EXISTS lmp_ng_games  (
    "game_id" INTEGER PRIMARY KEY AUTOINCREMENT,
    "is_final_series" NUMERIC NOT NULL DEFAULT 0,
    "game_status" NUMERIC NOT NULL DEFAULT 0,
    "game_running" NUMERIC NOT NULL DEFAULT 0,
    "game_completed" NUMERIC NOT NULL DEFAULT 0,
    "game_synced" NUMERIC NOT NULL DEFAULT 0,
    "team_a_points" INTEGER DEFAULT 0,
    "team_a_colour" TEXT,
    "team_a_background" TEXT,
    "team_b_points" INTEGER DEFAULT 0,
    "team_b_colour" TEXT,
    "team_b_background" TEXT,
    "game_attendance" TEXT,
    "game_temp_players" TEXT,
    "game_fouls" TEXT,
    "g_division_id" INTEGER NOT NULL,
    "g_team_a_id" INTEGER,
    "g_team_b_id" INTEGER,
    "g_team_duty_id" INTEGER,
    "g_team_bye_id" INTEGER,
    "created_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "updated_at" TEXT DEFAULT CURRENT_TIMESTAMP
);

CREATE TABLE IF NOT EXISTS lmp_ng_games_schedule  (
    "game_schedule_id" INTEGER PRIMARY KEY AUTOINCREMENT,
    "gs_date" TEXT NOT NULL,
    "gs_time" time(0),
    "gs_game_id" INTEGER NOT NULL,
    "gs_court_id" INTEGER NOT NULL,
    "created_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "updated_at" TEXT DEFAULT CURRENT_TIMESTAMP
);

CREATE TABLE IF NOT EXISTS lmp_ng_teams  (
    "team_id" INTEGER PRIMARY KEY AUTOINCREMENT,
    "team_name" TEXT NOT NULL,
    "team_slug" TEXT NOT NULL,
    "team_contact_manager" INTEGER,
    "team_contact_coach" INTEGER,
    "team_contact_assistant" INTEGER,
    "team_singlet_colour" TEXT,
    "team_number_colour" TEXT,
    "t_division_id" INTEGER NOT NULL,
    "created_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "updated_at" TEXT DEFAULT CURRENT_TIMESTAMP
);

CREATE TABLE IF NOT EXISTS lmp_ng_members  (
    "member_id" INTEGER PRIMARY KEY AUTOINCREMENT,
    "member_firstname" TEXT,
    "member_lastname" TEXT,
    "member_dob" TEXT,
    "member_gender" TEXT NOT NULL,
    "suspend_until_date" TEXT,
    "reason" TEXT,
    "is_suspended" NUMERIC NOT NULL DEFAULT 0,
    "is_rep" NUMERIC NOT NULL DEFAULT 0,
    "member_expire_date" TEXT,
    "tshirt_number" TEXT,
    "created_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "updated_at" TEXT DEFAULT CURRENT_TIMESTAMP
);

CREATE TABLE IF NOT EXISTS lmp_ng_teams_members  (
    "team_member_id" INTEGER PRIMARY KEY AUTOINCREMENT,
    "tm_team_id" INTEGER NOT NULL,
    "tm_member_id" INTEGER NOT NULL,
    "tm_member_playing_number" TEXT,
    "tm_temp_member" NUMERIC DEFAULT 0,
    "created_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "updated_at" TEXT
);
`;

export const CREATE_TABLE_SCORE = `
CREATE TABLE IF NOT EXISTS lmp_ng_scores_app  (
    "score_id" INTEGER PRIMARY KEY AUTOINCREMENT,
    "sc_game_id" INTEGER NOT NULL,
    "sc_member_id" INTEGER NOT NULL,
    "sc_team_id" INTEGER NOT NULL,
    "points" INTEGER DEFAULT 0,
    "1_points" INTEGER DEFAULT 0,
    "2_points" INTEGER DEFAULT 0,
    "3_points" INTEGER DEFAULT 0,
    "fouls" INTEGER DEFAULT 0,
    "techfouls" INTEGER DEFAULT 0,
    "disqfouls" INTEGER DEFAULT 0,
    "unspfouls" INTEGER DEFAULT 0,
    "tshirt_number" TEXT DEFAULT "00",
    "referee_fullname_startgame" TEXT,
    "referee_fullname_endgame" TEXT,
    "disabled" INTEGER DEFAULT 0,
    "created_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "updated_at" TEXT
);

CREATE UNIQUE INDEX IF NOT EXISTS idx_score_app_game_member_team
ON lmp_ng_scores_app (sc_game_id, sc_member_id, sc_team_id);

CREATE TABLE IF NOT EXISTS lmp_ng_score_logs_app  (
    "log_id" INTEGER PRIMARY KEY AUTOINCREMENT,
    "log_game_id" INTEGER NOT NULL,
    "log_game_action" TEXT,
    "created_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "updated_at" TEXT
);

CREATE UNIQUE INDEX IF NOT EXISTS idx_score_log_app_game
ON lmp_ng_score_logs_app (log_game_id);

CREATE TABLE IF NOT EXISTS lmp_ng_score_setting_app  (
    "setting_id" INTEGER PRIMARY KEY AUTOINCREMENT,
    "setting_referee" TEXT,
    "setting_staff" TEXT,
    "setting_login" TEXT,
    "created_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "updated_at" TEXT
);
`;

export const ALTER_TABLE_1 = `
ALTER TABLE lmp_ng_games ADD COLUMN "game_temp_players" TEXT;
ALTER TABLE lmp_ng_teams_members ADD COLUMN "tm_temp_member" NUMERIC DEFAULT 0;
`;

export const ALTER_TABLE_2 = `
ALTER TABLE lmp_ng_teams ADD COLUMN "team_singlet_colour" TEXT;
ALTER TABLE lmp_ng_teams ADD COLUMN "team_number_colour" TEXT;
ALTER TABLE lmp_ng_teams_members ADD COLUMN "tm_member_playing_number" TEXT;
`;

export const ALTER_TABLE_3 = `
CREATE TABLE IF NOT EXISTS lmp_ng_teams2  (
    "team_id" INTEGER PRIMARY KEY AUTOINCREMENT,
    "team_name" TEXT NOT NULL,
    "team_slug" TEXT NOT NULL,
    "team_contact_manager" INTEGER,
    "team_contact_coach" INTEGER,
    "team_contact_assistant" INTEGER,
    "team_singlet_colour" TEXT,
    "team_number_colour" TEXT,
    "t_division_id" INTEGER NOT NULL,
    "created_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "updated_at" TEXT DEFAULT CURRENT_TIMESTAMP
);
INSERT INTO lmp_ng_teams2 SELECT * FROM lmp_ng_teams;
DROP TABLE lmp_ng_teams;
ALTER TABLE lmp_ng_teams2 RENAME TO lmp_ng_teams;
`;

export const ALTER_TABLE_4 = `
CREATE TABLE IF NOT EXISTS lmp_ng_teams_members2  (
    "team_member_id" INTEGER PRIMARY KEY AUTOINCREMENT,
    "tm_team_id" INTEGER NOT NULL,
    "tm_member_id" INTEGER NOT NULL,
    "tm_member_playing_number" TEXT,
    "tm_temp_member" NUMERIC DEFAULT 0,
    "created_at" TEXT DEFAULT CURRENT_TIMESTAMP,
    "updated_at" TEXT
);
INSERT INTO lmp_ng_teams_members2 SELECT * FROM lmp_ng_teams_members;
DROP TABLE lmp_ng_teams_members;
ALTER TABLE lmp_ng_teams_members2 RENAME TO lmp_ng_teams_members;
`;

export const ALTER_TABLE_5 = `
ALTER TABLE lmp_ng_games ADD COLUMN "game_fouls" TEXT;
`;

export const ALTER_TABLE_6 = `
ALTER TABLE lmp_ng_scores_app ADD COLUMN "sc_team_id" INTEGER;
DROP INDEX IF EXISTS idx_score_app_game_member;
CREATE UNIQUE INDEX IF NOT EXISTS idx_score_app_game_member_team
ON lmp_ng_scores_app (sc_game_id, sc_member_id, sc_team_id);
`;