import { defineCustomElements as jeepSqlite, applyPolyfills } from "jeep-sqlite/loader";
import { Capacitor } from '@capacitor/core';
import { CapacitorSQLite, SQLiteConnection } from '@capacitor-community/sqlite';
import {CREATE_TABLE, CREATE_TABLE_SCORE, ALTER_TABLE_1, ALTER_TABLE_2, ALTER_TABLE_3, ALTER_TABLE_4, ALTER_TABLE_5, ALTER_TABLE_6} from '@/utils/db-create';
import {DROP_TABLE} from '@/utils/db-drop';

const sqliteService = () => {
    const platform = Capacitor.getPlatform();
    let db;

    const databaseStartup = async () => {
        const sqlite = new SQLiteConnection(CapacitorSQLite);

        try {
            // console.log(platform);
            if(platform === "web") {
                // Create the 'jeep-sqlite' Stencil component
                const jeepSqlite = document.createElement('jeep-sqlite');
                document.body.appendChild(jeepSqlite);
                await customElements.whenDefined('jeep-sqlite');
                // Initialize the Web store
                await sqlite.initWebStore();
                // console.log('init web store');
            }

            const ret = await sqlite.checkConnectionsConsistency();
            const isConn = (await sqlite.isConnection('lmp-db')).result;

            if (ret.result && isConn) {
                db = await sqlite.retrieveConnection('lmp-db');
                // console.log('retrieve connection');
                // await db.delete(); //@TODO remove for PROD
            }
            else { //@TODO comment out for prod
                if(platform === "web") {
                    db = await sqlite.createConnection('lmp-db', false, 'no-encryption', 1);
                    // console.log('create non-encrypted connection');
                } else {
                    db = await sqlite.createConnection('lmp-db');
                    // console.log('create encrypted connection');
                }
            }

            if (!db) throw new Error("NO DATABASE");

            await db.open();

            // console.log("database opened", db);

            return db;
        } catch (error) {
            console.log('error init db', error);
        }
    };

    const closeDb = async (db) => {
        try {
            await db.close();
        } catch (error) {
            // console.log('error close db', error);
            throw new Error(error);
        }
    };

    const openDb = async (db) => {
        try {
            await db.open();
        } catch (error) {
            // console.log('error open db', error);
            throw new Error(error);
        }
    };

    const initDbTables = async () => {
        try {
            // let resp_drop = await db.execute(DROP_TABLE); //@TODO remove for PROD
            let resp = await db.execute(CREATE_TABLE);
            let resp_score = await db.execute(CREATE_TABLE_SCORE);

            try {
                let alter_table_1 = await db.execute(ALTER_TABLE_1);
                // console.log('alter_table_1 fields added');
            } catch (err) {
                // console.log('alter_table_1 fields already in there');
            }

            try {
                let alter_table_2 = await db.execute(ALTER_TABLE_2);
                // console.log('alter_table_2 fields added');
            } catch (err) {
                // console.log('alter_table_2 fields already in there');
            }

            try {
                let alter_table_3 = await db.execute(ALTER_TABLE_3);
                // console.log('alter_table_3 fields added');
            } catch (err) {
                // console.log('alter_table_3 fields already in there');
            }

            try {
                let alter_table_4 = await db.execute(ALTER_TABLE_4);
                // console.log('alter_table_4 fields added');
            } catch (err) {
                // console.log('alter_table_4 fields already in there');
            }

            try {
                let alter_table_5 = await db.execute(ALTER_TABLE_5);
                // console.log('alter_table_5 fields added');
            } catch (err) {
                // console.log('alter_table_5 fields already in there');
            }

            try {
                let alter_table_6 = await db.execute(ALTER_TABLE_6);
                // console.log('alter_table_6 fields added');
            } catch (err) {
                // console.log('alter_table_6 fields already in there');
            }

            if(resp.changes && resp.changes.changes && resp.changes.changes < 0) {
                throw new Error('Error: created failed');
            }

            // console.log('init tables');
        } catch (error) {
            // console.log('error init tables', error);
            throw new Error(error);
        }
    };

    return {
        // FUNCTIONS
        databaseStartup,
        initDbTables,
        openDb,
        closeDb,
    };
};

export default sqliteService;